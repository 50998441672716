import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { usePage } from "../context/PageContext"
import { BrinkContext } from "../context/BrinkContext"
import { containerLargeMaxWidth, MEDIA_MIN_X_LARGE } from "../../constants"
import Link from "../../components/Link"

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 2rem 2.5rem;

  ${MEDIA_MIN_X_LARGE} {
    padding: 1.6rem 0;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  border: none;
  font-size: 1.2rem;
  max-width: ${containerLargeMaxWidth};
  margin: 0 auto;
  line-height: 1.6rem;
  letter-spacing: 0;

  ${MEDIA_MIN_X_LARGE} {
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
  }
`

const Divider = styled.span`
  margin: 0 0.8rem;
`

const Breadcrumbs = ({ location, children, ...other }) => {
  const { collections, buildLanguage } = usePage()
  const { languageCode } = useContext(BrinkContext)
  const { t } = useTranslation("translations")

  return (
    <Wrapper {...other}>
      <Container>
        <Link to="/">{t("Home")}</Link>
        {location &&
          location.state &&
          location.state.prevPath &&
          location.state.prevPath.split("/").map(
            (crumb) =>
              collections &&
              collections.map((item) =>
                (item.intSlug[buildLanguage] || item.intSlug.en) === crumb ? (
                  <Link
                    key={item._id}
                    to={`/${item.intSlug[buildLanguage] || item.intSlug.en}/`}
                  >
                    <Divider>/</Divider>
                    {item.pageTitle[languageCode] || item.pageTitle.en}
                  </Link>
                ) : (
                  item.childCollection &&
                  item.childCollections.map(
                    (child) =>
                      (child.slug[buildLanguage] || child.slug.en) ===
                        crumb && (
                        <Link
                          key={child._id}
                          to={`/${item.slug[buildLanguage] || item.slug.en}/${
                            child.slug[buildLanguage] || child.slug.en
                          }/`}
                        >
                          <Divider>/</Divider>
                          {child.title[languageCode] || child.title.en}
                        </Link>
                      )
                  )
                )
              )
          )}
        <Divider>/</Divider>
        {children}
      </Container>
    </Wrapper>
  )
}

export default Breadcrumbs
