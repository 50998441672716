import React from "react"
import styled from "styled-components"
import { containerLargeMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import Card from "../product/card/Card"
import LineHeading from "../ui/LineHeading"

const Container = styled.div`
  max-width: ${containerLargeMaxWidth};
  margin: 0 auto;
  padding: 0;
`

const Products = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0.75rem;

  > div {
    padding: 0 0.75rem;


  ${MEDIA_MIN_MEDIUM} {
    padding: 0;

    > div {
      padding: 0 1.5rem;
    }
  }
`

const ProductGrid = ({ columns, products, heading, path, ...other }) => {
  if (!products) return null

  return (
    <Container {...other}>
      {heading && <LineHeading>{heading}</LineHeading>}
      <Products>
        {products.map((product, index) => {
          return (
            <Card
              key={product._id}
              product={product}
              columns={columns}
              path={path}
              positionInList={index}
            />
          )
        })}
      </Products>
    </Container>
  )
}

export default ProductGrid
