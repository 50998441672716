import React, { useContext } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../../context/BrinkContext"
import {
  MEDIA_MEDIUM,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../../constants"
import Price from "../Price"

const CardWrapper = styled.div`
  position: relative;
  max-width: 22rem;
  padding: 0.5rem;
  width: ${(p) => (p.slider ? "100%" : "calc(50% - 0.2rem)")};

  ${MEDIA_MIN_MEDIUM} {
    max-width: 25.6rem;
    padding: 0.5rem;
    width: ${(p) => (p.slider ? "100%" : "calc(100% / 3 - 0.2rem)")};
  }

  ${MEDIA_MEDIUM} {
    width: calc(100% / ${(p) => p.columns} - 0.2rem);
    max-width: 50rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled(GatsbyImage)`
  margin: 0 auto;
`

const SecondaryImage = styled(GatsbyImage)`
  position: absolute !important;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s;

  img {
    object-position: center top !important;
  }
`

const Name = styled.h2`
  margin: 1.5rem 0 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.2rem;
  padding: 0;
  text-align: left;

  ${MEDIA_MIN_MEDIUM} {
    line-height: 2rem;
    margin: 1.5rem 0 0;
    height: auto;
  }
`

const PriceContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0;
`

const FormattedPrice = styled(Price)`
  margin: 0.5rem auto 0;

  span {
    font-size: 1.5rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 0.2rem auto 0;

    span {
      font-size: 1.6rem;
    }
  }
`

const Badge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1.2rem;
  top: 1.2rem;
  height: 2.5rem;
  font-size: 1.1rem;
  padding: 0.6rem 1.2rem 0.4rem;
  background: ${(p) =>
    p.outOfStock ? p.theme.colors.mediumGrey : p.theme.colors.white};
  color: ${(p) => p.theme.colors.secondary};
  text-transform: uppercase;
  z-index: 2;
  font-weight: 400;
  letter-spacing: 0.05rem;

  ${MEDIA_MIN_MEDIUM} {
    top: 1.5rem;
    left: 1.5rem;
    font-size: 1.3rem;
    padding: 1rem 2rem;
    height: 3rem;
  }
`

const GatsbyLink = ({
  path,
  product,
  columns,
  slider,
  positionInList,
  listName,
  stocks,
  ...other
}) => {
  const { t } = useTranslation("translations")
  const { languageCode } = useContext(BrinkContext)

  if (!product) return null

  const {
    displayName,
    badge: productBadge,
    comingSoon,
    slug,
    variants,
    useProductImages,
    mainImage: productMainImage,
    images: productImages,
    category
  } = product
  if (!variants.length) return null

  const badge =
    productBadge && productBadge.en ? productBadge : category && category.badge

  const outOfStock = product.variants.every((v) => !!v.outOfStock)
  const variant = product.variants[0]
  const {
    mainImage: variantMainImage,
    images: variantImages,
    children
  } = variant
  const { price, discount } = children[0] || {}

  if (!price) return null

  const mainImage = useProductImages ? productMainImage : variantMainImage
  const images = useProductImages ? productImages : variantImages

  return (
    <CardWrapper slider={slider} columns={columns}>
      <Link
        {...other}
        to={`/product/${slug.current}/`}
        state={{
          prevPath: path,
          clickProduct: {
            listName: listName
              ? listName
              : product.category
              ? product.category.displayName.en
              : "",
            positionInList: positionInList
          }
        }}
      >
        <ImageContainer>
          <Image
            image={getImage(mainImage?.asset)}
            className="product-card-primary-image"
            alt={displayName[languageCode] || displayName.en}
          />
          {images.length > 0 && (
            <SecondaryImage
              image={getImage(images[0].asset)}
              className="product-card-secondary-image"
              alt={displayName[languageCode] || displayName.en}
            />
          )}
          {outOfStock && <Badge outOfStock>{t("Out of stock")}</Badge>}
          {comingSoon && !outOfStock && <Badge>{t("Coming soon")}</Badge>}
          {badge && badge.en && !outOfStock && (
            <Badge>{badge[languageCode] || badge.en}</Badge>
          )}
        </ImageContainer>
        <div>
          <Name>{displayName[languageCode] || displayName.en}</Name>
          <PriceContainer>
            <FormattedPrice price={price} allDiscount={discount} />
          </PriceContainer>
        </div>
      </Link>
    </CardWrapper>
  )
}

const Card = styled(GatsbyLink)`
  margin-bottom: 0;
  text-decoration: none;
  color: ${(p) => p.theme.colors.black};
  display: block;
  height: 100%;
  padding: 1rem 0 2rem;
  background: ${(p) => p.theme.colors.white};

  &:focus {
    outline: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 1rem 3rem;
  }

  ${MEDIA_MIN_LARGE} {
    &:hover {
      > div div:nth-child(2) {
        opacity: 1;
      }
    }
  }
`

export default Card
