import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const H2 = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin: 0 auto 3rem;
  position: relative;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3rem;
  }

  span {
    display: inline-block;
    padding: 0 2rem;

    position: relative;
    z-index: 1;

    i {
      color: ${(p) => p.theme.colors.primary};
    }
  }

  &:before {
    content: "";
    height: 1.5rem;
    background: ${(p) => p.theme.colors.primary};
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 1.4rem;
    z-index: 0;

    ${MEDIA_MIN_MEDIUM} {
      left: 0rem;
      right: 0rem;
      top: 2rem;
      height: 2rem;
    }
  }
`

const LineHeading = ({ children, ...other }) => {
  if (!children) return null

  return (
    <H2 {...other}>
      <span>{children}</span>
    </H2>
  )
}

export default LineHeading
