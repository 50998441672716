import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  containerLargeMaxWidth,
  MEDIUM,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_X_LARGE
} from "../constants"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Link from "../components/Link"
import ProductGrid from "../components/widgets/ProductGrid"
import * as events from "../components/context/utils/events"
import queryString from "query-string"

const Hero = styled(BackgroundImage)`
  width: 100%;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center;

  ${MEDIA_MIN_MEDIUM} {
    height: 70rem;
    justify-content: flex-start;
    padding-bottom: 3rem;
  }
`

const Content = styled.div`
  max-width: ${containerMaxWidth};
  width: 100%;
  margin: 0 auto;
  color: ${(p) => p.theme.colors.white};

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 auto;
  }
`

const Container = styled.div`
  max-width: ${containerLargeMaxWidth};
  margin: 2rem auto 6rem;
`

const Title = styled.h1`
  text-align: center;
  padding: 0 3rem;
  line-height: 2.8rem;
  font-size: 2.2rem;
  letter-spacing: 0.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3.6rem;
    padding: 0;
    margin-bottom: 2rem;
  }
`

const Description = styled.p`
  text-align: center;
  padding: 0 2rem;
  max-width: 90rem;
  line-height: 2rem;
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0 auto;
  text-transform: initial;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin: 0 auto;
  }
`

const TitleBelow = styled(Title)`
  line-height: 2.8rem;
  font-size: 2.2rem;
  padding-top: 1rem;

  ${MEDIA_MIN_X_LARGE} {
    font-size: 3.6rem;
    padding-top: 3rem;
  }
`

const DescriptionBelow = styled(Description)`
  padding: 2rem 3rem;
  font-size: 1.4rem;

  ${MEDIA_MIN_X_LARGE} {
    padding: 3rem;
    font-size: 1.6rem;
  }
`

const TotalProducts = styled.p`
  text-align: center;
  font-size: 1.2rem;
  position: absolute;
  top: -0.5rem;
  right: 0;
  margin: 0;

  ${MEDIA_MIN_X_LARGE} {
    font-size: 1.3rem;
  }
`

const CollectionBreadcrumbs = styled(Breadcrumbs)`
  padding: 1.2rem 1.5rem;
  color: ${(p) => p.theme.colors.black};

  > div {
    padding-right: 8rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 1.8rem 0;
  }
`

const Products = styled(ProductGrid)`
  padding: 1rem 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0;
  }
`

const Divider = styled.span`
  margin: 0 0.8rem;
`

const CollectionPage = ({
  data: { sanityCollectionPage, parentCollection },
  pageContext,
  location
}) => {
  const { t } = useTranslation("translations")
  const { languageCode, setDiscountCode, addDiscount } =
    useContext(BrinkContext)
  const {
    pageLayout,
    pageTitle,
    pageDescription,
    metaTitle,
    metaDescription,
    intSlug,
    collections,
    mobileImage,
    desktopImage
  } = sanityCollectionPage
  const products = collections.map((collection) => collection.products).flat(1)

  useEffect(() => {
    events.viewCollection(products, pageTitle.en)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const { discountCode } = queryString.parse(location.search)
  useEffect(() => {
    const applyCode = async (discountCode) => {
      await addDiscount({ code: discountCode })
    }

    if (discountCode) {
      applyCode(discountCode)
      setDiscountCode(discountCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode])

  const hasHero =
    pageLayout !== "noHero" && mobileImage !== null && desktopImage !== null

  const sources = hasHero
    ? [
        getImage(mobileImage.asset),
        {
          ...getImage(desktopImage.asset),
          media: `(min-width: ${MEDIUM}px)`
        }
      ]
    : null

  const localizedTitle = pageTitle
    ? pageTitle[languageCode] || pageTitle.en
    : null

  const localizedDescription = pageDescription
    ? pageDescription[languageCode] || pageDescription.en
    : null

  return (
    <Layout
      meta={
        metaTitle && metaDescription
          ? {
              title: metaTitle[languageCode] || metaTitle.en,
              description: metaDescription[languageCode] || metaDescription.en
            }
          : { title: "Rondure" }
      }
      pageContext={pageContext}
      intSlug={intSlug}
      invertedHeader
    >
      <CollectionBreadcrumbs
        hasHero={hasHero}
        className="collection-page-breadcrumbs"
      >
        {parentCollection ? (
          <>
            <Link
              to={`/${parentCollection.intSlug[pageContext.buildLanguage]}/`}
            >
              {parentCollection.pageTitle[languageCode] ||
                parentCollection.pageTitle.en}
            </Link>
            <Divider>/</Divider>
            <Link
              to={`/${parentCollection.intSlug[pageContext.buildLanguage]}/${
                intSlug[pageContext.buildLanguage]
              }/`}
            >
              {pageTitle[languageCode] || pageTitle.en}
            </Link>
          </>
        ) : (
          <>
            <Link to={`/${intSlug[pageContext.buildLanguage]}/`}>
              {pageTitle[languageCode] || pageTitle.en}
            </Link>
          </>
        )}
        <TotalProducts>
          {products.length}{" "}
          {products.length === 1 ? t("product") : t("products")}
        </TotalProducts>
      </CollectionBreadcrumbs>
      {hasHero && (
        <Hero
          {...convertToBgImage(sources)}
          className="collection-page-hero-container"
        >
          {pageLayout !== "heroContentBelow" && (
            <Content>
              {localizedTitle && <Title>{localizedTitle}</Title>}
              {localizedDescription && (
                <Description>{localizedDescription}</Description>
              )}
            </Content>
          )}
        </Hero>
      )}

      <Container>
        {(!hasHero || pageLayout === "heroContentBelow") && (
          <>
            {localizedTitle && <TitleBelow>{localizedTitle}</TitleBelow>}
            {localizedDescription && (
              <DescriptionBelow style={{ fontWeight: "400" }}>
                {localizedDescription}
              </DescriptionBelow>
            )}
          </>
        )}
        <Products columns="3" products={products} path={location.pathname} />
      </Container>
    </Layout>
  )
}

export default CollectionPage

export const query = graphql`
  query ($collectionPageId: String!) {
    sanityCollectionPage(_id: { eq: $collectionPageId }) {
      _id
      pageLayout
      pageTitle {
        en
        de
      }
      pageDescription {
        en
        de
      }
      metaTitle {
        en
        de
      }
      metaDescription {
        en
        de
      }
      slug {
        current
      }
      intSlug {
        en
        de
      }
      mobileImage {
        asset {
          gatsbyImageData(width: 900)
        }
      }
      desktopImage {
        asset {
          gatsbyImageData(width: 2800)
        }
      }
      collections {
        products {
          _id
          title
          displayName {
            en
            de
          }
          category {
            displayName {
              en
              de
            }
            badge {
              en
              de
            }
          }
          slug {
            current
          }
          comingSoon
          badge {
            en
            de
          }
          useProductImages
          mainImage {
            asset {
              gatsbyImageData(width: 950)
            }
          }
          images {
            asset {
              gatsbyImageData(width: 950)
            }
          }
          variants {
            ...ProductVariants
          }
        }
      }
    }
    parentCollection: sanityCollectionPage(
      childCollections: { elemMatch: { _id: { eq: $collectionPageId } } }
    ) {
      slug {
        current
      }
      intSlug {
        en
        de
      }
      pageTitle {
        en
        de
      }
    }
  }
`
